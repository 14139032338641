export default {
  hero: {
    title: ['Architecture', 'D\'interieur'],
    img: 'https://images.ctfassets.net/r346nz3uql81/5NWjExp2pTXxIlhDFOLK3D/0649be93e90351df7973c532b5d8a6cd/annesophielagoet_archi_hero_2.jpg',
  },
  prestation: {
    description: {
      title: 'Architecture d\'intérieur',
      titleColor: 'gold',
      baseline: 'PARTICULIER ou PROFESSIONNEL, vous avez décidé de rénover votre bien.',
      description: `Il vous faut optimiser les volumes intérieurs et (re)dynamiser les espaces 
      ? Harmoniser les couleurs et les matériaux, créer des agencements
      sur-mesure, établir des plans techniques ou encore déléguer la
      coordination de vos travaux ? Je vous propose mes services en architecture
      d’intérieur pour votre projet de rénovation (dans l’ancien ou dans le
      neuf) avec une prestation sur-mesure adaptée à vos besoins et votre
      budget.`,
    },
    stepByStep: {
      title: ['« UN PROJET', 'ETAPE PAR ETAPE. »'],
      testimonials: [
        {
          id: 'contact',
          title: 'contact',
          picto: 'https://images.ctfassets.net/r346nz3uql81/64ksHJqI5cPaN8fywDN3zF/9702c8deaca32ab24bdf3ee030e707a2/picto_archi_maison.svg',
          text: `
          <p>Un premier rdv pour visiter votre bien et établir le cahier des charges.<p>
          `,
        },
        {
          id: 'devis',
          title: 'devis',
          picto: 'https://images.ctfassets.net/r346nz3uql81/2tY6QTv5yAzJS0ciXZLfEh/11e8be3985401f70e289616848d244fa/picto_archi_enveloppe.svg',
          text: '<p>Suite au rdv, je vous fais parvenir un devis avec les prestations adaptées.</p>',
        },
        {
          id: 'projet',
          title: 'projet',
          picto: 'https://images.ctfassets.net/r346nz3uql81/7ILSWmBYvnjWaNpzuPAVZq/9054927c0560e1ce55914c2491fc67b1/picto_archi_metre.svg',
          text: `
          <p>Devis signé, la conception du projet peut commencer !</p>
          `,
        },
      ],
    },
    prestations: {
      sectionTitle: ['« DU CONCEPT', 'A LA REALISATION. »'],
      blockColor: 'color-11',
      contentImage: 'https://images.ctfassets.net/r346nz3uql81/6lilXACGpOJCs6NhSYKkGO/2ea6246d731d89f6168425a7fbd03eae/annesophielagoet_archi_moulure.jpg?w=2300&q=50&fm=jpg&fit=fill',
      contentTitle: ['UNE PALETTE', 'DE PRESTATIONS'],
      contentBaseline: 'Des planches outils à mixer selon vos besoins :',
      contentList: [
        'planches inspirations & recherche de style',
        'plans d’agencement',
        'plans techniques',
        'sélections couleurs et matériaux',
        'croquis 3D couleur',
        'design meuble à menuiser : dressing, verrière…',
        'suivi et coordination de chantier…',
      ],
    },
    projectInImages: {
      sectionTitle: '« LES PLANCHES OUTILS... »',
      navigations: [
        'agencement',
        'inspiration',
        'technique',
        'materiaux',
        'croquis',
        'design-mobilier',
      ],
      toolboards: {
        inspiration: {
          id: 'inspiration',
          name: 'Inspirations',
          description: `
          <p>C’est une <strong>recherche de style!</strong></p>
          <p>Une planche de sélections d’images (également appelé moodboard) vous aidera à visualiser le <strong>style/concept idéal de votre future pièce.</strong></p>
          `,
          price: 290,
          picto: 'https://images.ctfassets.net/r346nz3uql81/3C7ZPcu0582FwgAbTeT5mF/efa44feeb2a46b7591b51c7dfd5ae047/carrousel_pastilles_archi_inspiration.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/2e1RJBJUE41Y8OTxAEhx2t/ea0a364e5473060aa3c4824acbb4fe95/1_2018_annesophielagoet_Meaux_Inspiration_Salon.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        agencement: {
          id: 'agencement',
          name: 'Agencement',
          description: `
          <p><strong>Optimisation de l’agencement</strong> de votre espace.</p>
          <p>Visualisation du projet sur plan en perspective.</p>
          `,
          price: 490,
          picto: 'https://images.ctfassets.net/r346nz3uql81/5pP0bhK4IqVKKBmX1agKpS/ae54bb9e4535de54c41be15481349c51/carrousel_pastilles_archi_agencement_archi.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/DACLFlFDREIsUD8SoXTYC/eaaf8e4d9ac30d9741dee09d692d8f8e/1_2021_annesophielagoet_Paris_Aboukir_Agencement_Studio.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        technique: {
          id: 'technique',
          name: 'Technique',
          description: `
          <p>Maçonnerie, électricité, plomberie, implantation cuisine…
          Les plans <strong>indispensables à la réalisation de votre projet.</strong>
          </p>
          <p>Les plans techniques sont <strong>accompagnés d’un DCE</strong> (dossier de consultation des entreprises).</p>
          <p><Le DCE comporte le détail des caractéristiques techniques de 
          chaque lot pour vous aider dans la consultation d’entrepreneurs 
          et dans l’élaboration de vos futurs devis travaux.</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/1Clnc68oZExATyQQ7uhnnn/af8aff599e625ec3cacdf958f142bc54/carrousel_pastilles_archi_technique.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/5wpuDi3Ob5dTEUMfD9Yrkc/f939db4109ceb5c8b2a457eb2a26f05a/1_2018_annesophielagoet_Paris_StMArtin_Technique_Electricite.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        materiaux: {
          id: 'materiaux',
          name: 'Matériaux',
          description: `
          <p>Peintures, papier peint, sol, matériaux cuisine/salle de bain...</p>
          <p>Je vous guide dans le choix de vos achats matériaux et de 
          leur implantation à l’aide de shopping liste, d’échantillons, de rdv en showroom… </p>
          `,
          price: 190,
          picto: 'https://images.ctfassets.net/r346nz3uql81/1uG76JMQMCNu7Zpizl9kRg/85e76115769baea1bd04688ee3839c59/carrousel_pastilles_archi_materiaux.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/55ZL7ZvAEOFCD9hc4Jdpms/d10efc83d04f7d1c39cca7d1317867b7/1_annesophielagoet_Archi_Materiaux_Eaubonne.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        'design-mobilier': {
          id: 'design-mobilier',
          name: 'Design mobilier',
          description: `
          <p>Une verrière? Une bibliothèque? Des rangements ? 
          Le design mobilier apporte des <strong>solutions sur-mesure</strong> afin d’optimiser et de valoriser votre espace.
          <p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/6JulZsy4aceRxb4Juc2aHv/6e965dca8d3bdf698d3e957529af805e/carrousel_pastilles_archi_design-mobilier.svg',
          images: [
            'https:////images.ctfassets.net/r346nz3uql81/gKMnFSxHIOAyQcqpVLAhn/2de7d0569e68cefdd7de027f828a742b/1_2017_annesophielagoet_Etampes_Menuiserie_Mobilier_Femme.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        croquis: {
          id: 'croquis',
          name: 'Croquis',
          description: `
          <p>Des croquis pour visualiser en détail le nouvel 
          aménagement avec les matériaux, les menuiseries…
          implantés dans la pièce. Les croquis couleur 3D <strong>vous 
          permettront de vous projeter et vous accompagneront 
          dans la mise en place de votre nouvel intérieur.</strong><p>
          `,
          price: 350,
          picto: 'https://images.ctfassets.net/r346nz3uql81/4jLVTB9dpR4n5ICXfbjvHl/fc113a7c77c9cff1c89c2a4982284969/carrousel_pastilles_archi_croquis.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/5UoWLU44tbdan5avDhg47u/dae4d30b013995b006d4528e697adde9/1_2021_annesophielagoet_Paris_Aboukir_Croquis_Cuisine.jpg?w=2300&q=50&fm=jpg',
          ],
        },
      },
    },
    gallery: [
      {
        id: 1,
        image: 'https://images.ctfassets.net/r346nz3uql81/3I4oqY3ta7TkZHgjODYYJJ/ed217f87552f6430b970f892d4193d59/annesophielagoet_archi_diaporama_01.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      },
      {
        id: 2,
        image: 'https://images.ctfassets.net/r346nz3uql81/2zS6hH5N7XFZv29Dxcnbna/7368016cc62814654e46b710595311c3/annesophielagoet_archi_diaporama_02.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 8, y: 5 },
          text: 'Selection peinture',
        },
      },
      {
        id: 3,
        image: 'https://images.ctfassets.net/r346nz3uql81/5eUAbZ8f8l0UO5CSd1vIaw/578f77c81badbadb3753d880f03d4d15/annesophielagoet_archi_diaporama_03.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      },
      {
        id: 4,
        image: 'https://images.ctfassets.net/r346nz3uql81/1mMiF9SqFUKLN64DtAKacu/7ea3fc7f96514a1a1e87334f0a4e1378/annesophielagoet_archi_diaporama_04.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 5, y: 13 },
          text: 'Shopping déco',
        },
      },
      {
        id: 5,
        image: 'https://images.ctfassets.net/r346nz3uql81/5r0cPTniBJMyL7Flb2dZYg/0750f9e615591c6137586335e42a65bb/annesophielagoet_archi_diaporama_05.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      },
      {
        id: 6,
        image: 'https://images.ctfassets.net/r346nz3uql81/64D6mD1YSZYcZqTZS95Vot/14d304f7a785d7ebf60bef03fd2f38a5/annesophielagoet_archi_diaporama_06.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 13, y: 6 },
          text: 'Design mobiliier',
        },
      },
      {
        id: 7,
        image: 'https://images.ctfassets.net/r346nz3uql81/54EfWH5P2EHPN9XrFtwdL/aeb7bb35592e095657f18c9ee4a78dff/annesophielagoet_archi_diaporama_07.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      },
      {
        id: 8,
        image: 'https://images.ctfassets.net/r346nz3uql81/7vZxmV1TfIiMOViAB4bVCK/af2cd89300c61dbf70a3e4624f81cc1c/annesophielagoet_archi_diaporama_08.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 4, y: 10 },
          text: 'Cuisine',
        },
      },
      {
        id: 9,
        image: 'https://images.ctfassets.net/r346nz3uql81/Jd3iGOTfBfwA5942rFgSE/9978b448a099a762612a304016952467/annesophielagoet_archi_diaporama_09.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 5, y: 6 },
          text: 'Salle de bains',
        },
      },
      {
        id: 10,
        image: 'https://images.ctfassets.net/r346nz3uql81/4nmqB3DJCZq4ArSHVaAs2F/8a380902d2045f8ed913fe9d435dbdce/annesophielagoet_archi_diaporama_10.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 15, y: 4 },
          text: 'Suivi de chantier',
        },
      },
      {
        id: 11,
        image: 'https://images.ctfassets.net/r346nz3uql81/6gV6tc08zZzwDNC4KLJeFD/51ae6c3487171ca2fd5d2d4c2eb2965e/annesophielagoet_archi_diaporama_11.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 9, y: 9 },
          text: 'Visite en showroom',
        },
      },
      {
        id: 12,
        image: 'https://images.ctfassets.net/r346nz3uql81/5lt0pSoq5onN94pE6nMUBQ/bc849b4a4f81fe31e07cc38841de825f/annesophielagoet_deco_diaporama_12.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
        tooltip: {
          position: { x: 14, y: 13 },
          text: 'Visite en showroom',
        },
      },
    ],
  },
};
